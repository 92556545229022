
import axios from "axios";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import {
  SortAlphabeticalAscending,
  SortAlphabeticalDescending,
  CloseThick,
} from "mdue";

export default defineComponent({
  name: "Home",
  components: {
    SortAlphabeticalAscending,
    SortAlphabeticalDescending,
    CloseThick,
  },

  setup() {
    /* ------------------------------ 1. Fetch Data ----------------------------- */
    const countries = ref([]);
    const getTutorial = async () => {
      try {
        const response = await axios.get(
          "all?fields=name,capital,flags,region,population"
        );
        countries.value = response.data;
        // countries.value.sort(sortTable("name"));
      } catch (error) {
        console.error(error);
      }
    };

    /* -------------------------------- 2. Table -------------------------------- */
    const tableWidth = computed(() => {
      return selectedCountries.value.length > 0 ? "100%" : "70%";
    });

    /* ------------------------------- 3. Sorting ------------------------------- */
    let sortName = ref(false);
    let sortRegion = ref(false);
    let sortCapital = ref(false);
    let filter = ref("");
    const sortTable = (key: string) => {
      let asc: boolean;
      if (key === "name") {
        asc = sortName.value = !sortName.value;
      } else if (key === "region") {
        asc = sortRegion.value = !sortRegion.value;
      } else {
        asc = sortCapital.value = !sortCapital.value;
      }
      return function innerSort(a: any, b: any) {
        let varA;
        let varB;
        switch (key) {
          case "name":
            varA = a[key].common.toLowerCase();
            varB = b[key].common.toLowerCase();
            break;
          case "region":
            varA = a[key].toLowerCase();
            varB = b[key].toLowerCase();
            break;
          case "capital":
            varA = a[key][0] ? a[key][0].toLowerCase() : "";
            varB = b[key][0] ? b[key][0].toLowerCase() : "";
            break;
          default:
            break;
        }

        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return asc ? comparison : comparison * -1;
      };
    };

    /* ------------------------------ 5. Filtering ------------------------------ */
    const debounce = (fn: (arg0: never) => void, delay: number | undefined) => {
      let timeout: number | undefined;
      return (args: never) => {
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
          fn(args);
        }, delay);
      };
    };

    const onInput = debounce((search) => {
      filter.value = search;
    }, 500);
    const filteredRows = computed(() => {
      return countries.value.filter(
        (row: {
          name: { common: string };
          region: string;
          capital: string;
        }) => {
          const name = row.name.common.toLowerCase();
          const region = row.region.toLowerCase();
          const capital = row.capital[0] ? row.capital[0].toLowerCase() : "";

          const searchTerm = filter.value.toLowerCase();

          return (
            name.includes(searchTerm) ||
            region.includes(searchTerm) ||
            capital.includes(searchTerm)
          );
        }
      );
    });

    /* ---------------------------- 6. Row Selection ---------------------------- */
    const selectedCountries = ref([]);
    const selectAll = ref(false);
    const select = () => {
      selectedCountries.value = [];
      if (!selectAll.value) {
        selectedCountries.value = countries.value;
      }
    };
    const clearSelected = () => {
      selectedCountries.value = [];
      selectAll.value = false;
    };

    /* --------------------------- 7. String Higlight --------------------------- */
    const stringHighlight = (text: string) => {
      const matchExists = text
        .toLowerCase()
        .includes(filter.value.toLowerCase());
      if (!matchExists) return text;
      const re = new RegExp(filter.value, "ig");
      return text.replace(
        re,
        (matchedText: string) =>
          `<span style='background-color:#42b983'>${matchedText}</span>`
      );
    };

    /* -------------------------- 8. Storage mechanisms ------------------------- */
    onMounted(() => {
      console.log("Component is mounted!");
      getTutorial();
      if (localStorage.favorites) {
        selectedCountries.value = JSON.parse(
          localStorage.getItem("favorites") || "{}"
        );
      }
    });

    watch(selectedCountries, () => {
      localStorage.setItem(
        "favorites",
        JSON.stringify(selectedCountries.value)
      );
    });

    /* ------------------------------ 9. Bonus Task ----------------------------- */
    const countriesName = computed(() => {
      let a = [];
      if (selectedCountries.value.length > 0) {
        a = JSON.parse(JSON.stringify(selectedCountries.value));
      }
      return a.map((e: { name: { common: string } }) => e.name.common);
    });

    const countriesPopulation = computed(() => {
      let a = [];
      if (selectedCountries.value.length > 0) {
        a = JSON.parse(JSON.stringify(selectedCountries.value));
      }
      return a.map((e: { population: string }) => e.population);
    });

    const option = ref({
      title: {
        text: "World Total Population",
        left: "center",
      },
      color: ["#42b983"],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        data: ["Population"],
        top: "5%",
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        boundaryGap: [0, 0.01],
      },
      yAxis: {
        type: "category",
        data: countriesName,
      },
      series: [
        {
          name: "Population",
          type: "bar",
          data: countriesPopulation,
        },
      ],
    });

    return {
      countries,
      sortTable,
      sortName,
      sortRegion,
      sortCapital,
      onInput,
      filter,
      filteredRows,
      stringHighlight,
      selectedCountries,
      selectAll,
      select,
      option,
      tableWidth,
      clearSelected,
    };
  },
});
